.gridHeader {
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    text-align: left;
    text-shadow: 0 1px 0 #fff;
    box-shadow: 0 1px 0 #fff inset;
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(1, 1fr);   
    max-height: 400px;
    overflow: auto;   
}

.hide{
    display: none;
}
    